<template>
  <Page :color="color" :title="title">
    <template slot="before">
      <InternEmbed :page="page" :key="count" />
    </template>
    <template v-if="help" slot="help">{{ help }}</template>
  </Page>
</template>

<script>
import { bus } from '@/main';

export default {
  name: 'Intern',
  props: ['title', 'color', 'page', 'help'],
  data() {
    return { count: 1 };
  },
  created() {
    bus.$on('menu', () => {
      this.count = this.count + 1;
    });
  },
};
</script>
